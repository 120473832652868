<template>
  <v-app id="app">
    <!-- v-bind="currentProperties"  -->
    <router-view @eventname="playmusic" @eventname2="closedialog" v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
    <!-- <div>
      <v-dialog v-model="dialog" persistent width="auto">
        <v-card>
          <v-card-title class="text-h5"> 系統公告 </v-card-title>
          <v-card-text>重要：實名登記開戶事項 </v-card-text>
          <v-card-text
            >開戶/註冊帳戶「必須」使用真實英文全名和正確電話號碼登記，登記名字必須和綁定銀行名字一致；否則會不能正常上落分。
            如使用虛假姓名登記和不正確電話號碼導致發生各方面問題官方一概不會負責。
          </v-card-text>

          <v-btn color="green-darken-1" variant="text" @click="closedialog">
            確定
          </v-btn>
        </v-card>
      </v-dialog>
    </div> -->
    <audio muted controls ref="audio" class="aud">
      <!-- <source :src="mp3url" /> -->
    </audio>
  </v-app>
</template>

<script>
import WebStorageCache from 'web-storage-cache'
import { getArticleList } from './assets/api'
export default {
  name: 'App',
  data() {
    return {
      mp3url: '',
      isplaymusic: false,
      isactivemusic: false,
      //dialog: false,
      playmusicstatus: 'N',
      ArticleList: [],
    }
  },
  methods: {
    async getArticleListFun() {
      //this.isLoading = true
      let obj = {
        title: 'referscript',
      }
      try {
        const res = await getArticleList(obj)
        if (res && res.code === 100) {
          this.ArticleList = res.data

          this.fbinit()
        } else {
          this.ArticleList = []
        }
      } catch (error) {
      } finally {
        //this.isLoading = false
      }
    },
    getParameterByName(name, url = window.location.href) {
      let uri = url.split('?')
      let l_resultarray = []
      let promises = []
      var l_val = ''
      if (uri.length == 2) {
        let vars = uri[1].split('&')
        let getVars = {}
        let tmp = ''
        //console.log(vars)
        vars.forEach(function (v) {
          tmp = v.split('=')
          if (tmp.length == 2) {
            if (tmp[0] == name) {
              try {
                tmp[1] = decodeURIComponent(tmp[1])
              } catch (err) {}
              getVars[tmp[0]] = tmp[1]
              promises.push(getVars)
            }
          }
        })

        Promise.all(promises).then((l_resultarray = promises))

        if (l_resultarray.length > 0 && l_resultarray[0][name] != undefined && l_resultarray[0][name] != '') {
          l_val = l_resultarray[0][name]
        }

        //console.log(getVars);
        // do
      }
      return l_val
    },
    fbinit() {
      // eslint-disable-next-line
      if (fbq) {
        //console.log('aaa')
        //console.log(this.ArticleList)
        var l_hostname = window.location.hostname
        //console.log(l_hostname)
        var l_metaid = ''
        let l_checkdomain = this.ArticleList.filter(
          (f) => f.URL.trim() == l_hostname || 'www.' + f.URL.trim() == l_hostname
        )
        if (l_checkdomain != undefined && l_checkdomain.length > 0) {
          l_metaid = l_checkdomain[0].Description
        }

        if (l_metaid == undefined || l_metaid == '') {
          var l_ref = this.getParameterByName('ref') //this.$route.query?.ref
          if (l_ref != undefined) {
            l_ref = l_ref.trim()
            //  console.log(l_ref)
            let l_checkref = this.ArticleList.filter((f) => f.Brief.trim() == l_ref)
            // console.log(l_checkref)
            if (l_checkref != undefined && l_checkref.length > 0) {
              l_metaid = l_checkref[0].Description
            }
          }
        }
        //e
        // eslint-disable-next-line
        fbq('init', l_metaid != undefined && l_metaid != '' ? l_metaid : '803300185113567')
        // eslint-disable-next-line
        fbq('track', 'PageView')
      }
    },
    audioplayfn() {
      this.$refs.audio.load()
      let playPromise = this.$refs.audio.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.$refs.audio.play()
          })
          .catch(() => {})
      }
    },
    playmusic(p_isplay) {
      if (p_isplay && p_isplay == 'Y') {
        try {
          this.audioplayfn() //this.$refs.audio.play();
          this.isplaymusic = true
        } catch (e) {}
      } else {
        try {
          this.$refs.audio.pause()
          this.isplaymusic = false
        } catch (e) {}
      }
    },
    audioplay() {
      //   this.$refs.audio.src = 'https://www.newgame168.com/music/samplemusic.mp3' // l_music;
      //this.$refs.audio.play();
    },
    closedialog() {
      //this.dialog = false;
      this.isactivemusic = true
      try {
        this.audioplayfn() //this.$refs.audio.play();
        this.isplaymusic = true
      } catch (e) {}
    },
  },
  computed: {
    // currentProperties: function () {
    //   return { playmusicstatus: this.isplaymusic ? "Y" : "N" };
    // },
  },
  mounted() {
    document.title = this.$companyname + '娛樂'

    var cachecheck = new WebStorageCache({
      storage: 'localStorage',
      exp: Infinity,
    })

    cachecheck.set('isplaymusic', 'N')
    this.isplaymusic = false

    //this.dialog = true;
    cachecheck.set('isopendialog', 'Y')
    this.audioplay()
    window.addEventListener('pageshow', () => {
      if (this.isactivemusic) {
        try {
          if (this.isplaymusic) {
            this.audioplayfn() //this.$refs.audio.play();
            //this.isplaymusic = true;
          }
        } catch (e) {}
      }
    })

    window.addEventListener('pagehide', () => {
      if (this.isactivemusic) {
        try {
          this.$refs.audio.pause()
          this.isplaymusic = false
        } catch (e) {}
      }
    })

    window.addEventListener('focus', () => {
      // console.log("focus");
      if (this.isactivemusic) {
        try {
          if (this.isplaymusic) {
            this.audioplayfn() //this.$refs.audio.play();

            //this.isplaymusic = true;
          }
        } catch (e) {}
      }
    })

    window.addEventListener('blur', () => {
      // console.log("blur");
      if (this.isactivemusic) {
        try {
          this.$refs.audio.pause()
          //this.isplaymusic = false;
        } catch (e) {}
      }
    })

    this.$store.commit('setSiteScreenWidth', document.body.clientWidth)
    this.$store.commit('setSiteScreenHeight', document.body.clientHeight)
    window.addEventListener('resize', () => {
      console.log('resize')
      this.$store.commit('setSiteScreenWidth', document.body.clientWidth)
      this.$store.commit('setSiteScreenHeight', document.body.clientHeight)
    })
    this.getArticleListFun()
    //this.fbinit()
    // document.addEventListener("visibilitychange", function () {
    //   if (document.visibilityState === "visible") {
    //     if (this.isplaymusic) {
    //       try {
    //         this.$refs.audio.play();
    //       } catch (e) {}
    //     }
    //   } else {
    //     if (this.isplaymusic) {
    //       try {
    //         this.$refs.audio.pause();
    //       } catch (e) {}
    //     }
    //   }
    // });
  },
  created() {},
  watch: {
    isplaymusic(val) {
      var cachecheck = new WebStorageCache({
        storage: 'localStorage',
        exp: Infinity,
      })

      if (val != undefined) {
        if (val) {
          try {
            this.playmusicstatus = 'Y'
            cachecheck.set('isplaymusic', 'Y')
          } catch (e) {}
        } else {
          try {
            this.playmusicstatus = 'N'
            cachecheck.set('isplaymusic', 'N')
          } catch (e) {}
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
.aud {
  display: none;
}
</style>
<style lang="scss">
.v-application {
  .v-application--wrap {
    min-height: 100dvh;
  }
}
</style>
