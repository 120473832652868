<template>
  <v-overlay
    v-if="loadingFull.value || false"
    :value="true"
    :color="loadingFull.overlayColor || 'black'"
    :opacity="loadingFull.opacity || 0.6"
    z-index="999"
  >
    <v-progress-circular
      indeterminate
      :size="60"
      class="d-flex mx-auto"
    ></v-progress-circular>
    <v-chip
      :color="loadingFull.chipColor || 'white blue-grey--text'"
      class="mt-6 px-6 text-uppercase font-weight-bold"
    >
      {{ loadingFull.message || "Loading" }}
    </v-chip>
  </v-overlay>
</template>

<script>
export default {
  props: ["loadingFull"],
};
</script>
