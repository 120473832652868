import Vue from 'vue'
import App from './App.vue'
import { store } from './store/plugin'
import vuetify from './plugins/vuetify'
import router from './router'
import moment from 'moment'

import 'animate.css'
import './styles/variables1.scss'
import VueClipboard from 'vue-clipboard2'

import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'

//import VueAddtohomescreen from '@owliehq/vue-addtohomescreen'

//Vue.use(VueAddtohomescreen)

// Vue.use(VueAddtohomescreen, {
//   buttonColor: 'blue',
// })
import VueFriendlyIframe from 'vue-friendly-iframe'

Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.use(VueFriendlyIframe)

Vue.use(VueGtag, {
  config: { id: 'AW-16609401994' }, // don't forget to modify this!
})

//import VueFacebookPixel from 'vue-analytics-fb'

//Vue.use(VueFacebookPixel)

//Vue.analytics.fbq.init('840443638135210')

Vue.prototype.$moment = moment
Vue.prototype.$companyid = '4' //1:www.newgame168, 2:www.nicegame188.com, 3:www.infinity.com, 4:new interface
Vue.prototype.$companyname = 'easywin188' //1:"新一代", 2:"我們", 3:"無限", 4: new "nicegame168" change to "xxx", gamers666, easywin188
//+Vue.version
Vue.directive('lazy-load', {
  bind(el, binding) {
    const options = {
      rootMargin: '0px',
      threshold: 0.5,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 进入视窗，添加加载动画
          console.log(binding.value)
          entry.target.classList.add('animate__animated', binding.value)
          observer.unobserve(entry.target)
        }
      })
    }, options)

    observer.observe(el)
  },
})

import MySnackbar from '@/components/MySnackbar.vue'

import LoadingFull from '@/components/LoadingFull.vue'
Vue.component('MySnackbar', MySnackbar)
Vue.component('LoadingFull', LoadingFull)

Vue.config.productionTip = false

Vue.mixin({
  beforeCreate() {
    this.$store = store
  },
})

var app = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
console.log(app)
